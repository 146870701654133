<template>
  <div>
    <div class="viewEdit clock-in-out-table">
      <div class="card-header-edit align-items-center">
        <p class="title">
          Clock In/Out
        </p>
        <div class="d-flex clock-in-out">

          <custom-calender
            :start="startDate"
            :end="startDate"
            :date-range-config="dateDayRangeConfig"
            :from="'clock-in-out'"
            @date="updateDate"
          />

          <div class="set-time-export d-flex align-items-center">
            <b-form-group
              v-if="(userInfo && userInfo.role === 'SA') ||
                (userInfo.users && userInfo.users.length) ||
                (userInfo.manage_by && userInfo.manage_by.length)
              "
              class="select-group mb-0 mt-1 mt-xl-0"
              label-for="user_id"
            >
              <v-select
                v-model="user_id"
                name="status"
                label="name"
                :options="users"
                :reduce="(user) => user.id"
                class="select-user-clock"
                placeholder="Select members"
              >
                <template v-slot:option="option">
                  {{ option.full_name }} <span />
                </template>
                <template #selected-option="option">
                  {{ option.full_name }}</template>
              </v-select>
            </b-form-group>
            <Help
              v-show="$store.state.app.userManualList"
              code="ClockInOut"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <span
        style="font-weight: bold"
        class="total-hr-timespent"
      >Total Time: <span>{{ totalMinute }}</span>
      </span>
    </div>

    <div style="float: left; width: 100%">
      <table-loader
        v-if="loader"
        :fields="fields"
      />
    </div>
    <clock
      v-if="!loader"
      :data-log="data"
    />
  </div>
</template>

<script>
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import clock from '../../@core/components/clockInOut/inOutLog.vue'

export default {
  components: {
    clock,
  },
  data() {
    return {
      loader: false,
      startDate: moment().format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      dayDate: moment().format('YYYY-MM-DD'),
      users: [],
      data: [],
      user_id: null,
      dateDayRangeConfig: {
        inline: true,
        dateFormat: 'Y-m-d',
        mode: 'single',
        maxDate: new Date(),
      },
      startDateTime: null,
      fields: [
        {
          key: 'in_time',
          label: 'In',
          sortable: false,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'out_time',
          label: 'Out',
          sortable: false,
          thStyle: { width: '200px' },
          tdStyle: { width: '200px' },
        },
        {
          key: 'time_diff',
          label: 'Time',
          sortable: false,
          thStyle: { width: '97px' },
          tdStyle: { width: '97px' },
        },
      ],
    }
  },
  computed: {
    totalMinute() {
      return this.totalClockInOutMinute(this.data)
    },
  },
  watch: {
    user_id(nv) {
      if (nv) {
        this.routeQueryUpdate({ userId: nv })
      }
      this.clockInOut()
    },
  },

  mounted() {
    this.user_id = this.$route.query && this.$route.query.userId ? this.$route.query.userId : this.userInfo.id
    this.userListCall()
    eventBus.$emit('clockTimeUpdate')
    eventBus.$on('loadInOutList', data => {
      if (data) {
        this.clockInOut()
      }
    })
    eventBus.$on('LogUpdate', data => {
      if (data) {
        this.clockInOut()
      }
    })
    eventBus.$on('logListReLoad', data => {
      if (data) {
        this.clockInOut()
      }
    })
  },
  methods: {
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.dayDate = this.startDate
      this.clockInOut()
    },
    /**
     * get Sub Ordinate User List for employee role
    */
    async userListCall() {
      if (this.userInfo.role === 'SA' || this.userInfo.role === 'HR') {
        if (this.$store.state.app.usersList.length === 0) {
          this.users = await this.userList()
        } else {
          this.users = this.$store.state.app.usersList
        }
      } else {
        this.getSubOrdinateUserList()
      }
    },

    async getSubOrdinateUserList() {
      const response = await this.getHTTPPostResponse(
        'admin/master/user/get-sub-ordinate-user-hierarchy',
        {},
        false,
      )
      if (response && response.data) {
        const { data } = response
        // sort on name
        this.users = data.user
        this.users.sort((a, b) => {
          const nameA = `${a.name} ${a.last_name}`.toUpperCase()
          const nameB = `${b.name} ${b.last_name}`.toUpperCase()
          return nameA.localeCompare(nameB)
        })
      }
    },

    /* user List */
    async clockInOut() {
      this.loader = true
      if (!this.startDate) {
        return
      }
      const input = typeof this.user_id === 'object'
        ? {
          date: moment(this.startDate).format('YYYY-MM-DD'),
          user_id: this.userInfo.id,
        }
        : {
          date: moment(this.startDate).format('YYYY-MM-DD'),
          user_id: this.user_id,
        }

      if (this.userInfo.role == 'EMP' && this.userInfo.id !== this.user_id) {
        input.is_show_other_record = true
      }

      const response = await this.getHTTPPostResponse(
        'clock-in-out/list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.data = data
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/vieweditheader.css";
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/report.css";

.total-hr-timespent {
  color: #5e5873;
  font-weight: 600;
  font-size: 20px;
  text-align: end;
  margin-bottom: 15px;
  margin-right: 15px;

  span {
    color: #ff3700;
  }
}

.select-user-clock {
  width: 300px;

  .vs__selected {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 280px;
    text-transform: capitalize;
    color: #333333;
  }

  .vs__search {
    padding: 3px 7px;

    &::placeholder {
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  .vs__dropdown-toggle {
    background: #ffffff;
    border: 1px solid #dddbe2;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #333333 !important;
    opacity: 1;
  }

  .vs__selected-options {
    padding: 3px 6px;
  }

  .vs__open-indicator {
    fill: #333333;
  }

  .vs__clear {
    fill: #333333;
  }
}
.viewEdit .clock-in-out .dropdown-menu {
  left: -35px !important;
}
</style>
